<template>
    <div class="dataAnalysisTemplate">
        <div class="dataAnalysisTemplate_right">
            <el-scrollbar style="flex-shrink: 0;flex: 1">
                <div class="dataAnalysisTemplate_right_two">
                    <div class="dataAnalysisTemplate_right_two_title">题目</div>
                    <div class="dataAnalysisTemplate_right_two_content">{{echoData.question}}</div>
                </div>
                <div style="width: 100%;display: flex;flex-direction: column">
                    <div class="dataAnalysisTemplate_right_one">
                        <p style="width: 4px; height: 18px;background-color: #F23B2F; border-radius: 2px; margin-right: 5px"></p>
                        数据诊断
                    </div>
                    <div class="dataAnalysisTemplate_right_three mt">
                        <div class="dataAnalysisTemplate_right_three_title">回答</div>
                        <div class="dataAnalysisTemplate_right_three_content">
                            <div>
                                <el-input type="textarea" :rows="10" placeholder="请输入内容" v-model="form.content1"></el-input>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="width: 100%;display: flex;flex-direction: column">
                    <div class="dataAnalysisTemplate_right_one">
                        <p style="width: 4px; height: 18px;background-color: #F23B2F; border-radius: 2px; margin-right: 5px"></p>
                        优化建议
                    </div>
                    <div class="dataAnalysisTemplate_right_three mt">
                        <div class="dataAnalysisTemplate_right_three_title">回答</div>
                        <div class="dataAnalysisTemplate_right_three_content">
                            <div>
                                <el-input type="textarea" :rows="10" placeholder="请输入内容" v-model="form.content2"></el-input>
                            </div>
                        </div>
                    </div>
                    <div class="dataAnalysisTemplate_right_four">
                        <div class="dataAnalysisTemplate_right_four_btn dataAnalysisTemplate_right_four_btn2"
                             @click="save">保存
                        </div>
                    </div>
                </div>
            </el-scrollbar>
        </div>
    </div>
</template>

<script>
    import Editor from "@tinymce/tinymce-vue";
    import {Message} from 'element-ui';
    import { postImgUpload } from '@/utils/apis.js'

    export default {
        components: {
            "tinymce-editor": Editor
        },
        name: "dataAnalysisTemplate",
        props: {
            viewData: {
                type: Object,//规定值的类型
                default: () => {
                }
            },
            showData: {
                type: String,//规定值的类型
                default: ''
            },
            echoData: {
                type: Object,
                default: () => {
                }
            }
        },
        watch: {
            echoData(newVal, oldVal) {
                this.form.content1 = newVal.data_analysis
                this.form.content2 = newVal.problem_diagnosis
            },
            // activeNum(newVal, oldVal) {
            //     newVal === 0 ? this.oneTitle = '数据分析' : newVal === 1 ? this.oneTitle = '问题诊断' : this.oneTitle = '优化建议'
            // }
        },
        data() {
            return {
                iconArr: [
                    {
                        id: 1,
                        title: '数据分析',
                        icon: '&#xe657;'
                    },
                    {
                        id: 2,
                        title: '问题诊断',
                        icon: '&#xe64e;'
                    },
                    {
                        id: 3,
                        title: '优化建议',
                        icon: '&#xe68f;'
                    },
                ],
                activeNum: 0,
                init: {
                    selector: '#tinydemo',
                    language:'zh_CN',//注意大小写
                    height: 320,
                    width: 1000,
                    plugins: "lists code colorpicker textcolor wordcount contextmenu paste",
                    toolbar: "bold italic underline strikethrough | fontsizeselect | forecolor backcolor | alignleft aligncenter alignright alignjustify|bullist numlist |outdent indent blockquote | undo redo | link unlink image code | removeformat",
                    branding: false,
                    images_upload_handler: (blobInfo, success, failure) => {
                        var xhr, formData;
                        xhr = new XMLHttpRequest();
                        xhr.withCredentials = false;
                        xhr.open('POST', '/customer_data_analysis/img_upload');
                        xhr.setRequestHeader('Authorization', localStorage.getItem('token'))
                        xhr.onload = function() {
                            var json;
                            if (xhr.status == 403) {
                                failure('HTTP Error: ' + xhr.status, { remove: true });
                                return;
                            }
                            if (xhr.status < 200 || xhr.status >= 300 ) {
                                failure('上传文件出错: ' + xhr.response);
                                return;
                            }
                            json = JSON.parse(xhr.responseText);
                            if (!json || typeof json.location != 'string') {
                                failure('Invalid JSON: ' + xhr.responseText);
                                return;
                            }
                            success(json.location);
                        };

                        xhr.onerror = function () {
                            failure('Image upload failed due to a XHR Transport error. Code: ' + xhr.status);
                        }

                        formData = new FormData();
                        formData.append('img', blobInfo.blob(), blobInfo.filename());

                        xhr.send(formData);
                    },
                    paste_data_images: true, //开启图片拖拽上传
                    selector: 'textarea',  // change this value according to your HTML
                    deprecation_warnings: false,
                },
                form: {
                    content1: '',
                    content2: '',
                },
                oneTitle: '数据分析'
            }
        },
        mounted() {
            tinymce.init({});
            this.activeNum = 0
            if (sessionStorage.getItem(this.showData)) {
                let data = ''
                this.data = JSON.parse(sessionStorage.getItem(this.showData))
                this.form.content1 = this.data.content1
                this.form.content2 = this.data.content2
            }
        },
        methods: {
            async ajaxUpload(img) {
                let res = await postImgUpload({
                    img: img
                })
                console.log(res)
            },


            previousStep() {
                if (this.activeNum == 0) {
                    this.activeNum = 0
                } else {
                    this.activeNum--
                }
            },
            nextStep() {
                if (this.activeNum == 0 && this.form.content1 == '') {
                    this.$message({
                        message: '数据分析不能为空',
                        type: 'error'
                    })
                } else if (this.activeNum == 1 && this.form.content2 == '') {
                    this.$message({
                        message: '问题诊断不能为空',
                        type: 'error'
                    })
                } else {
                    this.activeNum++
                }

            },
            save() {
                if (this.form.content1 == '' && this.form.content2 == '') {
                    this.$message({
                        message: '请输入答案！',
                        type: 'error'
                    })
                } else {
                    this.$emit('save', this.form)
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    /deep/ .el-scrollbar__wrap {
        overflow-x: hidden;
    }

    .dataAnalysisTemplate {
        width: 100%;
        height: 100%;
        display: flex;

        .dataAnalysisTemplate_left {
            width: 131px;
            background-color: #F2F3FA;
            padding-top: 62px;
            z-index: -1;
            display: flex;
            flex-direction: column;

            .icon_box {
                text-align: center;

                .point_box {
                    display: flex;
                    padding: 0 0 10px 0;
                    flex-direction: column;
                    align-items: center;

                    .point {
                        width: 4px;
                        height: 4px;
                        background-color: #C6C6C6;
                        border-radius: 50%;
                        margin-top: 10px;
                    }
                }
            }
        }

        .dataAnalysisTemplate_right {
            flex: 1;
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;

            .dataAnalysisTemplate_right_one {
                font-size: 18px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 500;
                color: #33475B;
                display: inline-flex;
                flex-direction: row;
                height: 18px;
                align-items: center;
                margin-left: 50px;
                margin-top: 60px;
            }

            .dataAnalysisTemplate_right_two {
                display: inline-flex;
                flex-direction: row;
                margin-top: 40px;
                margin-left: 30px;

                .dataAnalysisTemplate_right_two_title {
                    min-width: 30px;
                    font-size: 20px;
                    line-height: 20px;
                    font-family: PingFang SC-Regular, PingFang SC;
                    color: #333333;
                    margin-right: 20px;
                    font-weight: 700;
                }

                .dataAnalysisTemplate_right_two_content {
                    font-weight: 700;
                    align-items: center;
                    width: 1000px;
                    font-size: 18px;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 500;
                    color: #333333;
                    word-break: break-all;
                    word-wrap: break-word;
                }
            }

            .dataAnalysisTemplate_right_three {
                display: inline-flex;
                flex-direction: row;

                .dataAnalysisTemplate_right_three_title {
                    font-size: 14px;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 500;
                    color: #333333;
                    margin-right: 20px;
                    width: 30px;
                }

                .dataAnalysisTemplate_right_three_content {
                    width: 1000px;
                }
            }

            .dataAnalysisTemplate_right_four {
                margin-left: 115px;
                margin-top: 62px;
                display: inline-flex;
                flex-direction: row;

                .dataAnalysisTemplate_right_four_btn {
                    width: 84px;
                    height: 40px;
                    border: 1px solid #F23B2F;
                    border-radius: 4px;
                    text-align: center;
                    line-height: 40px;
                    margin-right: 24px;
                    cursor: pointer;
                    margin-bottom: 50px;
                }

                .dataAnalysisTemplate_right_four_btn1 {
                    color: #F23B2F;
                    font-size: 14px;
                }

                .dataAnalysisTemplate_right_four_btn2 {
                    background-color: #F23B2F;
                    color: #FFFFFF;
                }
            }
        }

        .mt {
            margin-top: 53px;
            margin-left: 68px;
        }

        .activeColor {
            color: #F23B2F !important;
        }

        .activeBgColor {
            background-color: #F23B2F !important;
        }
    }

    /*/deep/ .tox .tox-notifications-container .tox-notification {*/
    /*    display: none;*/
    /*}*/
</style>