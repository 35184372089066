<template>
    <div class="beforeService">
        <dataAnalysisTemplate :echoData="echoData" :showData="crowdPortrait" @save="save" :viewData="viewData"></dataAnalysisTemplate>
    </div>
</template>

<script>
    import dataAnalysisTemplate from "./dataAnalysisTemplate";
    import {getDataAnalysisInfo} from "../../../../utils/apis";

export default {
        name: "beforeService",
        components: {
            dataAnalysisTemplate
        },
        data() {
            return {
                viewData: {},
                crowdPortrait: 'beforeService',
                echoData: {}
            }
        },
        methods: {
            save(val) {
                this.$emit('save', 6,val)
            },
            async getDataAnalysisInfoFn() {
                let res = await getDataAnalysisInfo({
                    type: 6
                })
                this.echoData = res.data
            }
        },
        mounted() {
            this.getDataAnalysisInfoFn()
        }
    }
</script>

<style scoped>
    .beforeService {
        width: 100%;
        height: 100%;
    }
</style>